import type { FormikHelpers, FormikValues } from 'formik';
import type { ReactNode } from 'react';
import Form from '~/components/forms/Form';
import useFingerprint from '~/hooks/useFingerprint';
import type { FormInput } from '~/types/inputs';
import { getApiURL } from '~/utils/api';
import { getPostSubmissionRedirection, handleError } from '~/utils/form';
import { stringifyUrlParameters } from '~/utils/string';

export default function Enter({
	inputs,
	buttonLabel,
	privacyPolicyRedirect,
	termsRedirect,
	minimumAge,
	children = null,
}: {
	inputs: FormInput[];
	buttonLabel: string;
	privacyPolicyRedirect: string;
	termsRedirect: string;
	minimumAge: number;
	children?: ReactNode | null;
}) {
	const { getVisitorId } = useFingerprint();

	const handleSubmit = async (
		values: FormikValues,
		recaptcha: string,
		setErrors: FormikHelpers<FormikValues>['setErrors'],
	) => {
		const { visitorId, requestId } = await getVisitorId();

		let payload;

		try {
			payload = {
				values: {
					...values,
					recaptcha,
					mobile: values.mobile ? values.mobile.replace(/[\s-]/g, '') : undefined,
				},
				meta: {
					fingerprintId: visitorId,
					requestId,
				},
			};
		} catch (e) {
			throw new Error('Failed to generate payload for submission', {
				cause: {
					error: e,
					ko: true,
					recoverable: false,
				},
			});
		}

		const submissionEndpoint = `${getApiURL()}/forms/entry`;

		try {
			new URL(submissionEndpoint);
		} catch (e) {
			throw new Error('Failed to construct submission endpoint', {
				cause: {
					error: e,
					ko: true,
					recoverable: false,
				},
			});
		}

		const req = fetch(submissionEndpoint, {
			method: 'POST',
			body: JSON.stringify(payload),
			headers: {
				'Content-Type': 'application/json',
			},
		});

		let response;

		try {
			response = await req;
		} catch (e) {
			throw new Error('Failed to submit form entry', {
				cause: {
					error: e,
					ko: true,
					recoverable: true,
				},
			});
		}

		let data;

		try {
			data = await response.json();
		} catch (e) {
			throw new Error('Failed to parse entry submission response', {
				cause: {
					error: e,
					ko: true,
					recoverable: false,
				},
			});
		}

		if (response.ok) {
			window.location.href = `${getPostSubmissionRedirection(data)}?${stringifyUrlParameters({
				...data.data,
				mobile: data.data.requiresVerification ? values.mobile : undefined,
			})}`;
		} else {
			const entryLimitMessage = data?.error?.errors?.entry_limit_reached;

			if (typeof entryLimitMessage === 'string') {
				return window.location.assign(`/entry-limit-reached?message=${entryLimitMessage}`);
			}

			handleError(data, setErrors);

			throw new Error('Failed to submit form entry', {
				cause: {
					error: JSON.stringify(data),
					ko: true,
					recoverable: data?.error?.code === 'validation_failed',
				},
			});
		}
	};

	return (
		<Form
			inputs={inputs}
			onSubmit={handleSubmit}
			privacyPolicyRedirect={privacyPolicyRedirect}
			termsRedirect={termsRedirect}
			buttonChildren={buttonLabel}
			minimumAge={minimumAge}
		></Form>
	);
}
